import '../../assets/scss/slick-theme.scss';
import '../../assets/scss/slick.scss';

import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import Slider from 'react-slick';

import { EnumsGamePackVersion } from '@lp-lib/api-service-client/public';

import { useAwaitFullScreenConfirmCancelModal } from '../../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../../components/ConfirmCancelModalContext/ModalWrapper';
import {
  useGameLikeEmitterForDiscover,
  useGameLikeEventEmitter,
  useGameLikeWorkspace,
  useTriggerManagePinnedGamePacksModal,
} from '../../components/Game/GameCenter';
import {
  type RowProps,
  RowWrapper,
  useGameLikeRowLoader,
} from '../../components/Game/GameCenter/Row';
import { useOpenRoutedGamePackEditor } from '../../components/Game/GamePack';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  type GamePackCardStyles,
} from '../../components/Game/GamePack/GamePackCard';
import { useUser } from '../../components/UserContext';
import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import {
  type GamePacksResponse,
  type Paginator,
} from '../../services/api-service';
import { RoleUtils, virtualTagIds } from '../../types';
import { type GamePack } from '../../types/game';
import {
  type GamePackPickedProps,
  useGamePackContext,
  useGamePackLoader,
  useGamePackSliderSettings,
  useNumPerRow,
} from './Context';
import { GamePackCardBottomAccessory } from './GamePackCardBottomAccessory';

type Props = GamePackPickedProps &
  RowProps & {
    paginator: Paginator<GamePacksResponse, GamePack>;
  };

export const Row = (props: Props): JSX.Element | null => {
  const ctx = useGamePackContext();
  const { paginator, onGamePackClick, ...rest } = props;
  const { items, dao, state, error, handleRetry, handleLoad } =
    useGameLikeRowLoader(paginator);
  const [, setCreatingGamePack] = useGameLikeWorkspace('gamePack', 'create');
  const sliderSettings = useGamePackSliderSettings();
  const numPerRow = useNumPerRow();
  const loader = useGamePackLoader(numPerRow);
  const emitter = useGameLikeEventEmitter('gamePack');
  const isAdmin = RoleUtils.isAdmin(useUser());

  useGameLikeEmitterForDiscover(emitter, dao, props.tag.id);
  const managePinnedGamePacks = useTriggerManagePinnedGamePacksModal();
  const navigate = useNavigate();
  const chooseVersion = useAwaitFullScreenConfirmCancelModal();
  const openGamePackEditor = useOpenRoutedGamePackEditor();
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const gamePackStyles = useMemo<GamePackCardStyles>(() => {
    return {
      size: 'w-full',
    };
  }, []);

  const handleCardClick = (pack: GamePack) => {
    if (onGamePackClick) {
      onGamePackClick(pack);
      return;
    }
    setActiveGamePack(pack);
  };

  const handleCreateGamePack = () => {
    const v2Enabled = getFeatureQueryParam('gpv2');
    if (v2Enabled) {
      chooseVersion({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-120'
            borderStyle='gray'
            onClose={p.internalOnCancel}
          >
            <div className='w-full flex flex-col items-center justify-center gap-6 p-6'>
              <div className='text-2xl font-medium text-center'>
                Which version of game pack would you like to create?
              </div>
              <div className='flex items-center justify-center gap-4'>
                <button
                  type='button'
                  className='w-33 h-10 btn-secondary items-center justify-center'
                  onClick={() => {
                    p.internalOnConfirm();
                    openGamePackEditor({
                      type: 'create',
                      version: EnumsGamePackVersion.GamePackVersionV1,
                    });
                  }}
                >
                  v1
                </button>
                <button
                  type='button'
                  className='w-33 h-10 btn-primary flex items-center justify-center'
                  onClick={() => {
                    p.internalOnConfirm();
                    openGamePackEditor({
                      type: 'create',
                      version: EnumsGamePackVersion.GamePackVersionV2,
                    });
                  }}
                >
                  v2
                </button>
              </div>
            </div>
          </ModalWrapper>
        ),
      });
    } else {
      if (ctx.embed) {
        setCreatingGamePack(true);
      } else {
        navigate(`${ctx.routePrefix}/v1/create`);
      }
    }
  };

  const pinnedManager = (
    <button
      type='button'
      className='btn-secondary w-20 h-6 ml-8 opacity-0 group-hover:opacity-100 text-sms rounded-md'
      onClick={() => {
        managePinnedGamePacks({
          tag: props.tag,
          onComplete: handleRetry,
        });
      }}
    >
      Reorder
    </button>
  );

  const body = (
    <Slider {...sliderSettings}>
      {items.map((p) => (
        <div className='pt-5 px-2' key={p.id}>
          <GamePackCard
            gamePack={p}
            onClick={handleCardClick}
            badges={<DefaultGamePackCardBadges gamePack={p} />}
            bottomAccessory={<GamePackCardBottomAccessory pack={p} />}
            styles={gamePackStyles}
            showVersion={!ctx.embed}
          />
        </div>
      ))}
    </Slider>
  );

  return (
    <RowWrapper
      {...rest}
      ctx={ctx}
      type='gamePack'
      loader={loader}
      state={state}
      error={error}
      handleCreate={handleCreateGamePack}
      handleLoad={handleLoad}
      handleRetry={handleRetry}
      isEmpty={items.length === 0}
      pinnedManager={
        !virtualTagIds.includes(props.tag.id) && isAdmin
          ? pinnedManager
          : undefined
      }
      headerSpacing='mb-0'
      rowSpacing='mb-0'
    >
      {body}
    </RowWrapper>
  );
};
